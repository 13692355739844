import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "grid", "uploadZone", "title", "saveButton", "visibilityToggle", "publishToggle", "coverPhoto",
    "titleInput", "content", "drawer", "errorMessage", 
    "articlesMenu", "articlesCount", "articlesButton", "articleTitle", 
    "articleError", "submitButton", "responseData", "modalFooter"
  ]

  static values = {
    projectId: String,
    canUpdate: Boolean
  }

  connect() {
    console.log('Project Controller Connected')
    console.log('Project ID Value:', this.projectIdValue)
    console.log('Has Grid Target:', this.hasGridTarget)
    console.log('Has Upload Zone Target:', this.hasUploadZoneTarget)
    
    if (this.hasGridTarget) {
      this.initializeSortable()
    }
    if (this.hasTitleTarget) {
      this.originalTitle = this.titleTarget.value
    }

    // Initialize Bootstrap components
    this.initializeBootstrapComponents()

    // Only load articles if we have the required targets
    if (this.hasArticlesCountTarget && this.hasArticlesMenuTarget) {
      this.loadArticles()
    }
  }

  initializeBootstrapComponents() {
    // Initialize dropdowns
    const dropdowns = this.element.querySelectorAll('.dropdown-toggle')
    dropdowns.forEach(dropdown => {
      $(dropdown).dropdown()  // Using jQuery for Bootstrap 3 compatibility
    })

    // Initialize modals with improved XHR handling
    $(document).on('click', '[data-toggle="modal"][data-remote="true"]', (e) => {
      e.preventDefault()
      const link = e.currentTarget
      const url = link.href
      const targetModal = link.dataset.target
      const $modal = $(targetModal)
      
      // Show loading state
      $modal.find('.modal-dialog').html(`
                    <h4 class="modal-title">Loading...</h4>

      `)
      $modal.modal('show')

      // Fetch with explicit headers
      fetch(url, {
        headers: {
          'Accept': 'text/html',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
        .then(response => {
          if (!response.ok) throw new Error('Network response was not ok')
          return response.text()
        })
        .then(html => {
          // Wrap the response in modal-content if it's not already wrapped
          const $content = $(html)
          if (!$content.hasClass('modal-content')) {
            html = `
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 class="modal-title">${link.textContent}</h4>
                </div>
                <div class="modal-body">
                  ${html}
                </div>
              </div>
            `
          }
          $modal.find('.modal-dialog').html(html)
        })
        .catch(error => {
          console.error('Error loading modal:', error)
          $modal.find('.modal-dialog').html(`
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Error</h4>
              </div>
              <div class="modal-body">
                <div class="alert alert-danger">
                  Failed to load content. Please try again.
                </div>
              </div>
            </div>
          `)
        })
    })
  }

  initializeSortable() {
    import('sortablejs').then(({ default: Sortable }) => {
      console.log('Initializing Sortable with Project ID:', this.projectIdValue)
      
      new Sortable(this.gridTarget, {
        animation: 150,
        onEnd: async (event) => {
          console.log('Reorder triggered with Project ID:', this.projectIdValue)
          const photos = Array.from(this.gridTarget.children).map(el => el.dataset.photoId)
          
          if (!this.projectIdValue) {
            console.error('Project ID is missing in reorder handler')
            return
          }
          
          try {
            const url = `/projects/${this.projectIdValue}/photos/reorder`
            console.log('Making reorder request to:', url)
            
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                'Accept': 'application/json'
              },
              body: JSON.stringify({ photo: photos })
            })

            if (!response.ok) {
              const error = await response.text()
              throw new Error(`Reorder failed: ${error}`)
            }

          } catch (error) {
            console.error('Failed to reorder photos:', error)
          }
        }
      })
    })
  }

  toggleAddPhotos(event) {
    console.log('Toggle add photos')
    event.preventDefault()
    
    if (!this.hasUploadZoneTarget) {
      console.error('No upload zone target found')
      return
    }

    // Toggle upload zone visibility
    this.uploadZoneTarget.classList.toggle('hide')

    // Update button text
    const button = event.currentTarget
    const isShowing = !this.uploadZoneTarget.classList.contains('hide')
    console.log('Is showing:', isShowing)
    button.textContent = isShowing ? 'Hide Upload' : 'Add Photos'
  }

  toggleChangeCover() {
    // Implementation for change cover functionality
    console.log('Toggle change cover')
  }

  handleTitleInput(event) {
    if (event.target.value.trim() !== this.originalTitle) {
      this.saveButtonTarget.classList.remove('hide')
    } else {
      this.saveButtonTarget.classList.add('hide')
    }
  }

  async handleTitleKeydown(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      await this.saveTitle(event)
    }
  }

  async forceSaveTitle(event) {
    event.preventDefault()
    await this.saveTitle({ target: this.titleTarget })
  }

  async saveTitle(event) {
    const value = event.target.value.trim()
    if (!value) return
    
    try {
      const response = await fetch(`/projects/${this.projectIdValue}/update_title`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        },
        body: JSON.stringify({ title: value })
      })

      if (!response.ok) {
        throw new Error('Update failed')
      }

      // Update original title and hide save button
      this.originalTitle = value
      this.saveButtonTarget.classList.add('hide')

      // Show success indicator
      event.target.classList.add('success')
      setTimeout(() => event.target.classList.remove('success'), 2000)

    } catch (error) {
      console.error('Failed to save title:', error)
      event.target.classList.add('error')
      setTimeout(() => event.target.classList.remove('error'), 2000)
    }
  }

  async toggleVisibility(event) {
    event.preventDefault()
    const button = this.visibilityToggleTarget
    const currentlyVisible = button.classList.contains('visible')

    try {
      const response = await fetch(`/projects/${this.projectIdValue}/toggle_visibility`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        }
      })

      if (!response.ok) {
        throw new Error('Update failed')
      }

      const data = await response.json()
      
      // Update button state
      button.classList.toggle('visible')
      button.classList.toggle('private')
      
      // Update icon and text
      const icon = button.querySelector('i')
      icon.classList.toggle('fa-eye')
      icon.classList.toggle('fa-eye-slash')
      button.innerHTML = button.innerHTML.replace(
        currentlyVisible ? 'Public' : 'Private',
        currentlyVisible ? 'Private' : 'Public'
      )

      // If making private, also unpublish
      if (!data.visible && this.hasPublishToggleTarget) {
        this.publishToggleTarget.classList.remove('published')
        this.publishToggleTarget.classList.add('draft')
        const publishIcon = this.publishToggleTarget.querySelector('i')
        publishIcon.classList.remove('fa-globe')
        publishIcon.classList.add('fa-pencil')
        this.publishToggleTarget.innerHTML = this.publishToggleTarget.innerHTML.replace('Published', 'Draft')
      }

    } catch (error) {
      console.error('Failed to toggle visibility:', error)
      button.classList.add('error')
      setTimeout(() => button.classList.remove('error'), 2000)
    }
  }

  async togglePublished(event) {
    event.preventDefault()
    const button = this.publishToggleTarget
    const currentlyPublished = button.classList.contains('published')

    try {
      const response = await fetch(`/projects/${this.projectIdValue}/toggle_published`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        }
      })

      if (!response.ok) {
        throw new Error('Update failed')
      }

      const data = await response.json()
      
      // Update button state
      button.classList.toggle('published')
      button.classList.toggle('draft')
      
      // Update icon and text
      const icon = button.querySelector('i')
      icon.classList.toggle('fa-globe')
      icon.classList.toggle('fa-pencil')
      button.innerHTML = button.innerHTML.replace(
        currentlyPublished ? 'Published' : 'Draft',
        currentlyPublished ? 'Draft' : 'Published'
      )

      // If publishing, also make visible
      if (data.published && this.hasVisibilityToggleTarget) {
        this.visibilityToggleTarget.classList.add('visible')
        this.visibilityToggleTarget.classList.remove('private')
        const visibilityIcon = this.visibilityToggleTarget.querySelector('i')
        visibilityIcon.classList.add('fa-eye')
        visibilityIcon.classList.remove('fa-eye-slash')
        this.visibilityToggleTarget.innerHTML = this.visibilityToggleTarget.innerHTML.replace('Private', 'Visible')
      }

    } catch (error) {
      console.error('Failed to toggle published state:', error)
      button.classList.add('error')
      setTimeout(() => button.classList.remove('error'), 2000)
    }
  }

  async updateCoverPhoto(event) {
    event.preventDefault()
    const photoId = event.currentTarget.dataset.photoId
    
    try {
      const response = await fetch(`/projects/${this.projectIdValue}/photos/${photoId}/cover`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        }
      })

      if (!response.ok) throw new Error('Update failed')
      
      const data = await response.json()
      
      // Update the cover photo display
      if (this.hasCoverPhotoTarget) {
        this.coverPhotoTarget.innerHTML = `
          <img src="${data.cover_photo_url}" class="cover-preview" alt="Cover Photo">
        `
      }

      // Update star icons on all photos
      const stars = this.element.querySelectorAll('.cover-star')
      stars.forEach(star => {
        const isCurrent = star.dataset.photoId === photoId
        star.classList.toggle('active', isCurrent)
      })

    } catch (error) {
      console.error('Failed to update cover photo:', error)
    }
  }

  async loadArticles() {
    this.articlesCountTarget.textContent = '...'
    
    try {
      const response = await fetch(`/projects/${this.projectIdValue}/articles.json`)
      if (!response.ok) throw new Error('Failed to load articles')
      const articles = await response.json()
      
      this.articlesCountTarget.textContent = articles.length
      if (articles.length === 0) {
        this.articlesButtonTarget.classList.add('text-muted')
      } else {
        this.articlesButtonTarget.classList.remove('text-muted')
      }
      
      this.articlesMenuTarget.innerHTML = this.renderArticlesMenu(articles)
    } catch (error) {
      console.error('Failed to load articles:', error)
      this.articlesCountTarget.textContent = '0'
      this.articlesButtonTarget.classList.add('text-muted')
      this.articlesMenuTarget.innerHTML = '<li class="dropdown-header text-danger">Failed to load articles</li>'
    }
  }

  renderArticlesMenu(articles) {
    let content = `
      <div class="dropdown-header d-flex justify-content-between align-items-center">
        <span>${articles.length ? 'Articles' : 'No articles yet'}</span>
        <button type="button" class="close" data-action="project#toggleMenu">&times;</button>
      </div>
    `

    if (articles.length) {
      content += `
        <div class="articles-grid">
          ${articles.map(article => `
            <div class='flex w-100 justify-between items-center'>
              <a href="/articles/${article.tag}" class="dropdown-item tr" style="min-width:220px">
                ${article.title}
              </a>
              <a href="/articles/${article.tag}/edit" class="dropdown-item" style="max-width:60px">
                <i class="fa fa-pencil"></i>
              </a>
            </div>
          `).join('')}
        </div>
      `
    }

    return content
  }

  async toggleMenu(event) {
    if (event) event.preventDefault()
    
    // Simple toggle of show class
    this.articlesMenuTarget.classList.toggle('show')
    
    if (this.articlesMenuTarget.classList.contains('show')) {
      await this.loadArticles()
    }
  }

  openNewArticleModal(event) {
    if (event) event.preventDefault()
    
    // Rebuild the modal content
    const modalContent = `
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">New Article</h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="article_title">Title</label>
            <input type="text" id="article_title" class="form-control" required 
                   data-project-target="articleTitle">
            <div class="error-message text-danger mt-2" data-project-target="articleError"></div>
          </div>
          <div class="response-data mt-3" data-project-target="responseData"></div>
        </div>
        <div class="modal-footer" data-project-target="modalFooter">
          <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" 
                  data-action="project#createArticle"
                  data-project-target="submitButton">
            Create Article
          </button>
        </div>
      </div>
    `

    // Set the modal content
    const $modal = $('#new-article-modal')
    $modal.find('.modal-dialog').html(modalContent)
    
    // Reset form state
    this.resetModal()
    
    // Show the modal
    $modal.modal('show')
  }

  resetModal() {
    // Reset all form fields and error messages
    if (this.hasArticleTitleTarget) {
      this.articleTitleTarget.value = ''
    }
    if (this.hasArticleErrorTarget) {
      this.articleErrorTarget.textContent = ''
    }
    if (this.hasResponseDataTarget) {
      this.responseDataTarget.textContent = ''
    }
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = false
    }
    if (this.hasModalFooterTarget) {
      this.modalFooterTarget.style.display = 'block'
    }
  }

  async createArticle(event) {
    if (event) event.preventDefault()
    
    // Reset error message
    if (this.hasArticleErrorTarget) {
      this.articleErrorTarget.textContent = ''
    }

    // Get the article title
    const title = this.articleTitleTarget.value.trim()
    if (!title) {
      this.showError('Title cannot be empty')
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = false
      }
      return
    }

    console.log('Submitting article with title:', title)

    fetch('/articles/create_from_project', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'application/json'
      },
      body: JSON.stringify({ 
        project_id: this.projectIdValue,
        article: { title } 
      })
    })
    .then(response => {
      if (!response.ok) throw response
      return response.json()
    })
    .then(data => {
      console.log('Article created successfully:', data)
      
      // Hide footer controls
      if (this.hasModalFooterTarget) {
        this.modalFooterTarget.style.display = 'none'
      }

      // Show success message with buttons
      if (this.hasResponseDataTarget) {
        this.responseDataTarget.innerHTML = `
          <div class="text-center">
            <p>Successfully created article!</p>
            <a href="/articles/${data.tag}/edit" class="btn btn-primary">Edit Article</a>
            <a href="/articles/${data.tag}" class="btn btn-primary">View Article</a>
          </div>
        `
      }

      // Update article count if target exists
      if (this.hasArticlesCountTarget) {
        const currentCount = parseInt(this.articlesCountTarget.textContent) || 0
        this.articlesCountTarget.textContent = currentCount + 1
      }

      // Refresh articles menu
      this.loadArticles()
    })
    .catch(async error => {
      console.error('Failed to create article:', error)
      let errorMessage = 'Failed to create article'
      
      try {
        const data = await error.json()
        errorMessage = data.error || data.message || errorMessage
      } catch (e) {
        // Use default error message
      }
      
      this.showError(errorMessage)
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = false
      }
    })
  }

  showError(message) {
    console.error('Article creation error:', message)
    if (this.hasArticleErrorTarget) {
      this.articleErrorTarget.textContent = message
      this.articleErrorTarget.classList.remove('hidden')
    }
  }
}