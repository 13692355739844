import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileInput"]
  static values = {
    maxFiles: Number,
    maxFileSize: Number,
    projectId: String
  }

  connect() {
    this.dragCounter = 0
    this.bindEvents()
  }

  bindEvents() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.element.addEventListener(eventName, (e) => {
        e.preventDefault()
        e.stopPropagation()
      })
    })

    this.element.addEventListener('dragenter', this.handleDragEnter.bind(this))
    this.element.addEventListener('dragleave', this.handleDragLeave.bind(this))
    this.element.addEventListener('drop', this.handleDrop.bind(this))
    this.element.addEventListener('click', this.openFileDialog.bind(this))
  }

  openFileDialog(e) {
    this.fileInputTarget.click()
  }

  handleDragEnter(e) {
    this.dragCounter++
    this.element.classList.add('dragover')
  }

  handleDragLeave(e) {
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.element.classList.remove('dragover')
    }
  }

  handleDrop(e) {
    this.dragCounter = 0
    this.element.classList.remove('dragover')
    const files = e.dataTransfer.files
    this.uploadFiles(files)
  }

  handleFiles(e) {
    const files = e.target.files
    this.uploadFiles(files)
  }

  createUploadSkeleton(file) {
    const skeleton = document.createElement('div')
    skeleton.className = 'photo-upload-skeleton'
    skeleton.innerHTML = `
      <div class="skeleton-preview">
        <div class="skeleton-image"></div>
        <div class="skeleton-status">
          <div class="progress-bar">
            <div class="progress" style="width: 0%"></div>
          </div>
          <div class="status-text">Starting upload...</div>
        </div>
      </div>
    `
    
    // Create image preview
    const reader = new FileReader()
    reader.onload = (e) => {
      skeleton.querySelector('.skeleton-image').style.backgroundImage = `url(${e.target.result})`
    }
    reader.readAsDataURL(file)

    return skeleton
  }

  updateUploadProgress(skeleton, progress) {
    const progressBar = skeleton.querySelector('.progress')
    const statusText = skeleton.querySelector('.status-text')
    progressBar.style.width = `${progress}%`
    
    if (progress < 100) {
      statusText.textContent = `Uploading... ${progress}%`
    } else {
      statusText.textContent = 'Finalizing...'
    }
  }

  async uploadFiles(files) {
    const fileArray = Array.from(files)
    const grid = document.querySelector('.photo-grid')
    const uploadSkeletons = new Map()
    
    fileArray.forEach(file => {
      const skeleton = this.createUploadSkeleton(file)
      grid.insertBefore(skeleton, grid.firstChild)
      uploadSkeletons.set(file, skeleton)
    })
    
    try {
      const uploadPromises = fileArray.map(async (file) => {
        const skeleton = uploadSkeletons.get(file)
        
        // Get signed URL
        const signedUrlResponse = await fetch('/photos/new', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
          }
        })
        
        if (!signedUrlResponse.ok) throw new Error('Failed to get signed URL')
        
        const { url, payload, file: fileKey } = await signedUrlResponse.json()
        
        // Upload to S3 with progress tracking
        const formData = new FormData()
        Object.keys(payload).forEach(key => {
          formData.append(key, payload[key])
        })
        formData.append('file', file)
        
        const xhr = new XMLHttpRequest()
        await new Promise((resolve, reject) => {
          xhr.upload.onprogress = (e) => {
            if (e.lengthComputable) {
              const progress = Math.round((e.loaded / e.total) * 100)
              this.updateUploadProgress(skeleton, progress)
            }
          }
          
          xhr.onload = () => resolve(xhr.response)
          xhr.onerror = () => reject(new Error('Upload failed'))
          
          xhr.open('POST', url)
          xhr.send(formData)
        })
        
        // Create photo record
        const createResponse = await fetch(`/projects/${this.projectIdValue}/photos`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
          },
          body: JSON.stringify({
            file: fileKey,
            name: file.name
          })
        })
        
        if (!createResponse.ok) throw new Error('Failed to create photo')
        
        const photo = await createResponse.json()
        skeleton.querySelector('.status-text').textContent = 'Completed'
        setTimeout(() => {
          skeleton.classList.add('fade-out')
          setTimeout(() => skeleton.remove(), 500)
        }, 1000)
        
        return photo
      })
      
      await Promise.all(uploadPromises)
      window.location.reload()
      
    } catch (error) {
      console.error('Upload failed:', error)
      uploadSkeletons.forEach(skeleton => {
        skeleton.querySelector('.status-text').textContent = 'Upload failed'
        skeleton.classList.add('error')
      })
    }
  }
} 