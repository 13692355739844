import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log('Modal Controller Connected')
    console.log('Modal Controller element:', this.element)
    
    this.initializeBootstrapModals()
    
    document.addEventListener("openModal", (event) => {
      console.log('openModal event:', event)
      const { url, modalId, title } = event.detail
      const $targetModal = $(modalId)
      const $dialog = $targetModal.find('.modal-dialog')
      
      // Modify URL to include xhr=true
      const urlObj = new URL(url, window.location.origin)
      urlObj.searchParams.set('xhr', 'true')
      
      $dialog.html(` 
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">${title || 'Loading...'}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="text-center">Loading...</div>
          </div>
        </div>
      `)

      $targetModal.modal('show')

      fetch(urlObj.toString(), {
        headers: {
          'Accept': 'text/html',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
      .then(response => response.text())
      .then(html => {
        $dialog.html(html)
      })
      .catch(error => {
        console.error('Error:', error)
        $dialog.find('.modal-body').html('Error loading content')
      })
    })
  }

  initializeBootstrapModals() {
    $(document).on('click', '[data-toggle="modal"][data-remote="true"]', (e) => {
      e.preventDefault()
      const link = e.currentTarget
      const urlObj = new URL(link.href, window.location.origin)
      urlObj.searchParams.set('xhr', 'true')
      
      const targetModal = link.dataset.target
      const modalTitle = link.dataset.modalTitle || 'Loading...'
      const modalSubtitle = link.dataset.modalSubtitle || ''
      const modalIcon = link.dataset.modalIcon || ''
      const $modal = $(targetModal)
      const $dialog = $modal.find('.modal-dialog')

      $dialog.html(` 
        ${modalIcon ? `<i class="${modalIcon}"></i>` : ''}
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">${modalTitle}</h5>
            ${modalSubtitle ? `<h6 class="modal-subtitle">${modalSubtitle}</h6>` : ''}
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="text-center">Loading...</div>
          </div>
        </div>
      `)

      $modal.modal('show')

      fetch(urlObj.toString(), {
        headers: {
          'Accept': 'text/html',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
      .then(response => response.text())
      .then(html => {
        $dialog.html(html)
      })
      .catch(error => {
        console.error('Error:', error)
        $dialog.find('.modal-body').html('Error loading content')
      })
    })

    $('.modal').on('hidden.bs.modal', function() {
      const $modal = $(this)
      $modal.find('.modal-content').html("")
    })
  }
}