import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["display", "form"]
  static values = {
    inlineEditProjectId: String,
    inlineEditCollectionId: String
  }

  connect() {
    console.log("Inline Edit Controller Connected", {
      projectId: this.inlineEditProjectIdValue,
      collectionId: this.inlineEditCollectionIdValue
    })
  }

  // Get ID from URL path like /collections/123-title-slug or /projects/123-title-slug
  getIdFromPath() {
    const path = window.location.pathname
    const match = path.match(/\/(collections|projects)\/(\d+)/)
    if (match) {
      const [, type, id] = match
      return { type, id }
    }
    return null
  }

  showEdit(event) {
    if (event) event.preventDefault()
    this.displayTarget.classList.add('hide')
    this.formTarget.classList.remove('hide')
  }

  cancel(event) {
    if (event) event.preventDefault()
    this.formTarget.classList.add('hide')
    this.displayTarget.classList.remove('hide')
    // Reset textarea to original value
    this.formTarget.querySelector('textarea').value = this.displayTarget.querySelector('.description-content').textContent.trim()
  }

  async save(event) {
    if (event) event.preventDefault()
    
    const description = this.formTarget.querySelector('textarea').value
    let url, params

    // First try to get ID from data attributes
    if (this.hasInlineEditProjectIdValue) {
      url = `/projects/${this.inlineEditProjectIdValue}/update_description`
      params = { project: { description } }
    } else if (this.hasInlineEditCollectionIdValue) {
      url = `/collections/${this.inlineEditCollectionIdValue}/update_description`
      params = { collection: { description } }
    } else {
      // Fallback to URL parsing
      const urlInfo = this.getIdFromPath()
      if (urlInfo) {
        const { type, id } = urlInfo
        url = `/${type}/${id}/update_description`
        params = { [type.slice(0, -1)]: { description } }
      } else {
        console.error('Could not determine resource type and ID')
        alert('Could not determine if this is a project or collection. Please try refreshing the page.')
        return
      }
    }

    console.log('Making request to:', url, 'with params:', params)

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        },
        body: JSON.stringify(params)
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.error || 'Update failed')
      }

      // Update display content
      this.displayTarget.querySelector('.description-content').textContent = description
      
      // Switch back to display view
      this.formTarget.classList.add('hide')
      this.displayTarget.classList.remove('hide')

    } catch (error) {
      console.error('Failed to update description:', error)
      alert(error.message || 'Failed to save changes. Please try again.')
    }
  }
} 