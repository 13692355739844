import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["caption", "description", "copyright", "saveButton"]

  connect() {
    this.element.querySelectorAll('textarea, input').forEach(el => {
      el.addEventListener('input', this.handleInput.bind(this))
    })

    // Track original values
    this.originalValues = {
      caption: this.captionTarget?.value || '',
      description: this.descriptionTarget?.value || '',
      copyright: this.copyrightTarget?.value || ''
    }
  }

  handleInput(event) {
    this.updateCharacterCount(event)
    this.toggleSaveButton(event.target)
  }

  toggleSaveButton(field) {
    const saveButton = field.nextElementSibling.querySelector('.save-button')
    if (!saveButton) return

    const originalValue = this.originalValues[field.dataset.field]
    const hasChanges = field.value !== originalValue

    if (hasChanges) {
      saveButton.classList.remove('hide')
    } else {
      saveButton.classList.add('hide')
    }
  }

  async saveCaption(event) {
    event.preventDefault()
    const field = this.captionTarget
    await this.saveField('caption', field.value)
    this.originalValues.caption = field.value
    this.toggleSaveButton(field)
  }

  async saveDescription(event) {
    event.preventDefault()
    const field = this.descriptionTarget
    await this.saveField('description', field.value)
    this.originalValues.description = field.value
    this.toggleSaveButton(field)
  }

  async saveCopyright(event) {
    event.preventDefault()
    const field = this.copyrightTarget
    await this.saveField('copyright', field.value)
    this.originalValues.copyright = field.value
    this.toggleSaveButton(field)
  }

  async saveField(field, value) {
    const photoId = this.element.dataset.photoId
    const projectId = this.element.closest('[data-project-id-value]').dataset.projectIdValue

    try {
      const response = await fetch(`/photos/${photoId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        },
        body: JSON.stringify({ 
          photo: {
            [field]: value
          }
        })
      })

      if (!response.ok) {
        throw new Error('Update failed')
      }

      // Show success indicator
      const saveButton = this.element.querySelector(`[data-field="${field}"]`).nextElementSibling.querySelector('.save-button')
      if (saveButton) {
        const icon = saveButton.querySelector('i')
        icon.className = 'fa fa-check text-success'
        setTimeout(() => {
          icon.className = 'fa fa-save'
          saveButton.classList.add('hide')
        }, 2000)
      }

    } catch (error) {
      console.error('Failed to save:', error)
      const saveButton = this.element.querySelector(`[data-field="${field}"]`).nextElementSibling.querySelector('.save-button')
      if (saveButton) {
        const icon = saveButton.querySelector('i')
        icon.className = 'fa fa-times text-danger'
        setTimeout(() => {
          icon.className = 'fa fa-save'
        }, 2000)
      }
    }
  }

  async remove(event) {
    if (!confirm('Are you sure you want to remove this photo?')) return

    const photoId = this.element.dataset.photoId
    const projectId = this.element.closest('[data-project-id-value]').dataset.projectIdValue

    try {
      const response = await fetch(`/projects/${projectId}/photos/${photoId}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        }
      })

      if (!response.ok) {
        throw new Error('Remove failed')
      }
      
      this.element.remove()

    } catch (error) {
      console.error('Failed to remove:', error)
    }
  }

  async makeCover(event) {
    event.preventDefault()
    const photoId = this.element.dataset.photoId
    const projectId = this.findProjectId()

    try {
      const response = await fetch(`/projects/${projectId}/photos/${photoId}/cover`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        }
      })

      if (!response.ok) throw new Error('Failed to set cover photo')

      // Refresh the page to show updated cover state
      window.location.reload()

    } catch (error) {
      console.error('Failed to set cover photo:', error)
    }
  }

  findProjectId() {
    // Look for project ID in parent elements
    const projectContainer = this.element.closest('[data-project-project-id-value]')
    return projectContainer?.dataset.projectProjectIdValue
  }

  updateCharacterCount(event) {
    const field = event.target
    const counter = field.nextElementSibling.querySelector('.character-count')
    if (!counter) return
    
    let maxLength = 400 // default for caption
    if (field.classList.contains('photo-description')) maxLength = 1500
    if (field.classList.contains('photo-copyright')) maxLength = 40

    const remaining = maxLength - field.value.length
    counter.textContent = `${remaining} characters remaining`
    counter.style.display = 'block'

    // Auto-grow textareas
    if (field.tagName === 'TEXTAREA') {
      field.style.height = 'auto'
      field.style.height = field.scrollHeight + 'px'
    }
  }
} 