import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    photoId: String,
    fileId: String,
    processingUrl: { type: String, default: "https://assets.lensculture.com/static/img-misc/image-processing.png" },
    finalUrl: { type: String, default: "" },
    retryDelay: { type: Number, default: 3000 }
  }

  static targets = ["image"]

  connect() {
    this.retryCount = 0
    this.maxRetries = 3 // Max 9 seconds total
    this.checkPhotoStatus()

    // Force load the image after 10 seconds no matter what
    setTimeout(() => {
      this.loadFinalImage()
    }, 10000)
  }

  disconnect() {
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout)
    }
  }

  async checkPhotoStatus() {
    if (this.retryCount >= this.maxRetries) {
      this.loadFinalImage() // Just try to load the image
      return
    }

    try {
      const response = await fetch(`/photos/${this.photoIdValue}/processing`, {
        headers: { 'Accept': 'application/json' }
      })

      if (!response.ok) {
        this.loadFinalImage() // On error, try loading the image
        return
      }
      
      const data = await response.json()
      
      if (!data.processing) {
        this.loadFinalImage() // Not processing, load the image
        return
      }

      // Still processing, show processing image and retry
      this.imageTarget.src = this.processingUrlValue
      this.scheduleRetry()
      
    } catch (error) {
      console.error('Error checking photo status:', error)
      this.loadFinalImage() // On error, try loading the image
    }
  }

  loadFinalImage() {
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout) // Stop any pending retries
    }

    const finalUrl = this.finalUrlValue || `https://photos.lensculture.com/original/${this.fileIdValue}.jpg`
    this.imageTarget.src = finalUrl
    this.imageTarget.classList.remove('processing')
  }

  scheduleRetry() {
    this.retryCount++
    this.retryTimeout = setTimeout(() => {
      this.checkPhotoStatus()
    }, this.retryDelayValue)
  }
} 