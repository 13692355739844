// import "core-js/stable";
// import "regenerator-runtime/runtime";


import { Application } from "@hotwired/stimulus"

// Import controllers manually
import CollectionController from "../controllers/collection_controller"
import PhotoEditController from "../controllers/photo_edit_controller"
import ProjectController from "../controllers/project_controller"
import DropzoneController from "../controllers/dropzone_controller"
import PhotoLoaderController from "../controllers/photo_loader_controller"
import InlineEditController from "../controllers/inline_edit_controller"
import ModalController from "../controllers/modal_controller"

// Initialize Stimulus application
const application = Application.start()

// Register controllers
application.register("collection", CollectionController)
application.register("photo-edit", PhotoEditController)
application.register("project", ProjectController)
application.register("dropzone", DropzoneController)
application.register("photo-loader", PhotoLoaderController)
application.register("inline-edit", InlineEditController)
application.register("modal", ModalController)
// Export for potential use elsewhere
window.Stimulus = application
